import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ComponentBase } from '../../classes/base/component-base';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ConfigurationService } from '../../services/configuration.service';

@Component({
  selector: 'date-filter-control',
  templateUrl: './date-filter-control.component.html',
  styleUrls: ['./date-filter-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateFilterControlComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class DateFilterControlComponent extends ComponentBase implements OnInit, ControlValueAccessor {

  searchHtrCustomer: any = {
    fromDate: null,
    toDate: null
  }
  isShowFilter: boolean = false;
  filter_arr = [];
  vi: any;
  selectedIndex: number = -1;

  rangeDates: Date[] = [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()];
  showNavigationButtons: boolean = false;

  @Output() rangeDatesChange = new EventEmitter<any>();
  @Input() refTab: number = -1;

  constructor
    (
      private _configurationService: ConfigurationService
    ) {
    super();
  }

  ngOnInit() {
    this.vi = this._configurationService.calendarVietnamese;
    this.getDate();
    this.searchHtrCustomer = {
      fromDate: this.rangeDates[0],
      toDate: this.rangeDates[1]
    }
  }

  onChangeControl = (obj: any) => { };
  onTouched = () => { };


  writeValue(obj: any): void {
    throw new Error('Method not implemented.');
  }
  registerOnChange(fn: any): void {
    throw new Error('Method not implemented.');
  }
  registerOnTouched(fn: any): void {
    throw new Error('Method not implemented.');
  }
  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }

  // func DateTime

  showFilter() {
    this.isShowFilter = !this.isShowFilter;
  }

  getDate() {
    // Lấy ngày hôm nay và ngày hôm qua
    let today = new Date();
    let yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    // Lấy ngày đầu tuần và cuối tuần
    let firstDayOfWeek = new Date(today);
    firstDayOfWeek.setDate(today.getDate() - today.getDay() + 1);
    let lastDayOfWeek = new Date(today);
    lastDayOfWeek.setDate(today.getDate() + (7 - today.getDay()));

    // Lấy ngày đầu tháng và cuối tháng
    let firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    // Lấy ngày đầu và cuối của tuần trước
    let firstDaysWeekAgo = new Date(firstDayOfWeek);
    firstDaysWeekAgo.setDate(firstDayOfWeek.getDate() - 7);
    let lastDaysWeekAgo = new Date(lastDayOfWeek);
    lastDaysWeekAgo.setDate(lastDaysWeekAgo.getDate() - 7);

    // Lấy ngày đầu và cuối của tháng trước
    let firstDayOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    let lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0); // Ngày cuối của tháng trước

    // Lấy ngày đầu và cuối của 7 ngày trước
    let fSevenDayAgo = new Date(today);
    fSevenDayAgo.setDate(today.getDate() - 7);
    let lSevenDayAgo = new Date(today);

    // Lấy ngày đầu và cuối của 14 ngày trước
    let ftDayAgo = new Date(fSevenDayAgo);
    ftDayAgo.setDate(fSevenDayAgo.getDate() - 7);
    let ltDayAgo = new Date(today);

    // Lấy ngày đầu và cuối của 30 ngày trước
    let fttDayAgo = new Date(today);
    fttDayAgo.setDate(fttDayAgo.getDate() - 30);
    let lttDayAgo = new Date(today);

    // Lấy ngày đầu tiên của 10 năm trước
    let tenYearsAgo = new Date(today);
    tenYearsAgo.setFullYear(today.getFullYear() - 10);

    // Thêm option "Tất cả"
    this.filter_arr.push({
      label: 'Tất cả',
      fromDate: tenYearsAgo,
      toDate: today
    });
    this.filter_arr.push({
      label: 'Hôm nay',
      fromDate: today,
      toDate: today
    });
    this.filter_arr.push({
      label: 'Tuần này',
      fromDate: firstDayOfWeek,
      toDate: lastDayOfWeek
    });
    this.filter_arr.push({
      label: 'Tháng này',
      fromDate: firstDayOfMonth,
      toDate: lastDayOfMonth
    });
    this.filter_arr.push({
      label: 'Hôm qua',
      fromDate: yesterday,
      toDate: yesterday
    });
    this.filter_arr.push({
      label: 'Tuần trước',
      fromDate: firstDaysWeekAgo,
      toDate: lastDaysWeekAgo
    });
    this.filter_arr.push({
      label: 'Tháng trước',
      fromDate: firstDayOfLastMonth,
      toDate: lastDayOfLastMonth
    });
    this.filter_arr.push({
      label: '7 ngày trước',
      fromDate: fSevenDayAgo,
      toDate: today
    });
    this.filter_arr.push({
      label: '14 ngày trước',
      fromDate: ftDayAgo,
      toDate: ltDayAgo
    });
    this.filter_arr.push({
      label: '30 ngày trước',
      fromDate: fttDayAgo,
      toDate: lttDayAgo
    });
  }

  getOptionDatr(item: any, index: number) {
    this.searchHtrCustomer.fromDate = item.fromDate;
    this.searchHtrCustomer.toDate = item.toDate;
    this.selectedIndex = index;
    this.rangeDates = [item.fromDate, item.toDate];
    this.showNavigationButtons = item.label !== 'Tất cả';
  }

  applyDateRange(): void {
    this.rangeDatesChange.emit(this.rangeDates);
    this.onClose();
  }

  onClose() {
    this.isShowFilter = !this.isShowFilter;
  }

  onDateRangeSelect() {
    this.selectedIndex = -1;
    this.searchHtrCustomer = {
      fromDate: this.rangeDates[0],
      toDate: this.rangeDates[1]
    }
  }

  //handle date range selection
  adjustDate(direction: 'prev' | 'next'): void {
    const adjustment = direction === 'next' ? 1 : -1;

    if (this.selectedIndex === 0 || this.selectedIndex === 1) {

      // áp dụng cho hôm nay và hôm qua
      // copy old_date để xử lý ko bị nhảy 2 ngày
      const fromDateCopy = new Date(this.searchHtrCustomer.fromDate);
      const toDateCopy = new Date(this.searchHtrCustomer.toDate);

      fromDateCopy.setDate(fromDateCopy.getDate() + adjustment);
      toDateCopy.setDate(toDateCopy.getDate() + adjustment);

      this.searchHtrCustomer.fromDate = fromDateCopy;
      this.searchHtrCustomer.toDate = toDateCopy;

      this.rangeDates = [this.searchHtrCustomer.fromDate, this.searchHtrCustomer.toDate];
    }
  }



}
